import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LineIcon from "react-lineicons";

const BackButton = () => {
  return (
    <div className="text-right fixed-button-back">
        <Link className="mt-2 m-4 mb-1" to={"/"}>
          <Button>
            <LineIcon name="arrow-left" /> Regresar
          </Button>
        </Link>
      </div>
  )
}

export default BackButton