export const Enviroment = () => {
    return (
        <>
            {(() => {
                if (process.env.REACT_APP_URL_ENVIROMENT === "dev") {
                    return <div className="warning dev">Dev</div>;
                } else if (process.env.REACT_APP_URL_ENVIROMENT === "staging") {
                    return <div className="warning staging">Staging</div>;
                }
            })()}
        </>
    );
};

export default Enviroment;
