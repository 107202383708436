import { createContext, useContext, useState } from "react";
import lightLogo from "../assets/LogoPossumus.svg";
import darkLogo from "../assets/LogoPossumusDark.svg";
import christmasLogo from "../assets/LogoPossumusChristmas.svg";
import flake1 from "../assets/faqs/snowFlake1.svg";
import flake2 from "../assets/faqs/snowFlake2.svg";

const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [logo, setlogo] = useState(lightLogo);
  const [snowflakes, setSnowFlakes] = useState(false);
  const [actualTheme, setActualTheme] = useState(
    localStorage.getItem("theme") || "light"
  );

  const snowflake1 = document.createElement("img");
  snowflake1.src = flake1;
  const snowflake2 = document.createElement("img");
  snowflake2.src = flake2;

  const images = [snowflake1, snowflake2];

  function HandleThemeChange({ target }) {
    localStorage.setItem("theme", target.value);
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
    localStorage.setItem("theme", target.value);
    setActualTheme(target.value);

    if (target.value === "light") {
      setlogo(lightLogo);
      setSnowFlakes(false);
    } else if (target.value === "dark") {
      setlogo(darkLogo);
      setSnowFlakes(false);
    } else if (target.value === "christmas") {
      setlogo(christmasLogo);
      // setSnowFlakes(true);
    }
  }
  // useEffect(() => {
  //   localStorage.setItem("theme", "christmas");
  //   document
  //     .getElementsByTagName("HTML")[0]
  //     .setAttribute("data-theme", localStorage.getItem("theme"));
  //   setActualTheme("christmas");
  //   setlogo(christmasLogo);
  // }, []);
  return (
    <ThemeContext.Provider
      value={{ HandleThemeChange, logo, snowflakes, actualTheme, images }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
