import { Form, Col, Row, Image, Container } from "react-bootstrap";
import { useThemeContext } from "../../contexts/ThemeContext";
import Snowfall from "react-snowfall";

const Header = ({
  title,
  description,
  descriptionSub,
  col,
  paddingLeft,
  offset,
}) => {
  const { actualTheme, snowflakes, HandleThemeChange, logo, images } =
    useThemeContext();

  return (
    <Container fluid>
      {snowflakes && <Snowfall images={images} radius={[5, 20]} />}

      <Row className="text-center darkModeContainer">
        <Col md={{ span: 4, offset: 4 }}></Col>
        <Col md={2} className="text-end">
          <p>Tema</p>
        </Col>
        <Col md={2}>
          <Form.Select
            aria-label="Default select example"
            onChange={HandleThemeChange}
          >
            <option selected={actualTheme === "light"} value="light">
              Claro
            </option>
            <option selected={actualTheme === "dark"} value="dark">
              Oscuro
            </option>
            <option selected={actualTheme === "christmas"} value="christmas">
              Navidad
            </option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col
          md={{ span: col, offset: offset }}
          className="text-center headerFaqs"
          style={{ paddingLeft: paddingLeft }}
        >
          {logo && <Image src={logo} className="logo" />}

          <h1>{title}</h1>
          <p>{description}</p>
          <p>{descriptionSub}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
