import React, { Fragment, useEffect, useState } from "react";
import { Row, Container, Button, Col } from "react-bootstrap";
import MarkdownView from "react-showdown";
import Header from "../components/faqs/header";
import axios from "axios";
import BackButton from "../components/commons/backButton";

const PlatformCourses = () => {
  const [courses, setCourses] = useState();
  const [linkForm, setLinkForm] = useState("");
  const [linkFormCap, setLinkFormCap] = useState("");
  const [textCap, setTextCap] = useState("");
  const [textCapLink, setTextCapLink] = useState("");


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/cursos`)
      .then((res) => {
        setLinkForm(res.data[0]?.link_form);
        setLinkFormCap(res.data[0]?.link_form_cap);
        setTextCap(res.data[0]?.texto_capacitacion);
        setTextCapLink(res.data[0]?.texto_capacitacion_link);
        setCourses(res.data);
      })
      .catch((err) =>
        console.log(`Ocurrió un error al obtener los cursos: ${err.message}`)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="faqs">
      <BackButton />
      <Header
        title="Plataformas de cursos"
        description={`Poder reconocer la brecha que hay entre dónde estoy hoy en mi vida profesional y hasta donde quiero llegar, tomar la decisión de dar el primer paso para comenzar a achicar esa brecha y decidir capacitarse es una actitud valiosa.`}
        descriptionSub={
          "Desde Possumus celebramos la iniciativa y el desarrollo profesional, es por eso que ponemos a disposición de todos los colaboradores las siguientes plataformas educativas, te invitamos a que ingreses y según las necesidades de formación que tu rol requiera, selecciones aquella capacitación que te acerca a las metas."
        }
        col={7}
        paddingLeft={111}
        offset={2}
      />
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="text-center">
          <p>
            {
              "Para solicitar la capacitación tu lider deberá completar el siguiente "
            }
            <a href={linkForm} target="_blank" rel="noreferrer">
              formulario
            </a>
            .
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="text-center">
          <p>
            {`${textCap} `}
            <a href={linkFormCap} target="_blank" rel="noreferrer">
              {`${textCapLink}`}
            </a>
            .
          </p>
        </Col>
      </Row>
      <Container id="items">
        <Row id="portals">
          <Fragment>
            <div className="category-list-wrapper">
              {courses?.map((course, index) => {
                return (
                  <div className="category-list-item" key={index}>
                    <div className="widthCard cardStyle">
                      <MarkdownView
                        markdown={`### Credenciales Plataforma **${course.plataforma}**`}
                      />
                      <hr />
                      {course?.cuentas?.map((cuenta, index) =>
                        course?.cuentas.length > 1 ? (
                          <div key={index}>
                            <h5 className="textBold">{`Cuenta N° ${
                              index + 1
                            }`}</h5>
                            <h6>{`Usuario: ${cuenta?.user}`}</h6>
                            <h6>{`Contraseña: ${cuenta?.contrasenia} `}</h6>
                          </div>
                        ) : (
                          <div key={index}>
                            <h6>{`Usuario: ${cuenta?.user}`}</h6>
                            <h6>{`Contraseña: ${cuenta?.contrasenia} `}</h6>
                          </div>
                        )
                      )}
                      <a target="_blank" rel="noreferrer" href={course.link}>
                        <Button className="colorButton linkStyle">{`Ir a ${course.plataforma}`}</Button>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fragment>
        </Row>
      </Container>
    </div>
  );
};

export default PlatformCourses;
