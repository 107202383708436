import React, { Fragment, useContext } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import "../css/formRequest.css";
import { useHandleFormSteps } from "../hooks/useHandleFormSteps";
import { FormProvider, useForm } from "react-hook-form";
import { ContentFormRequestContext } from "../contexts/FormRequestContext";
import { StepOne } from "../components/formRequest/stepOne";
import { StepTwo } from "../components/formRequest/stepTwo";
import { FinalForm } from "../components/formRequest/finalForm";
import { AuthContext } from "../contexts/AuthContext";
import BackButton from "../components/commons/backButton";

const FormRequest = () => {
  const { formRequestData } = useContext(ContentFormRequestContext);

  const {actualUser} = useContext(AuthContext)

  const {
    date,
    setDate,
    actualOption,
    handleOptionChangeInStepOne,
    actualRequestType,
    handleRequestType,
    actualStep,
    dateAddDay,
    handleAdvanceStep,
    handleReturnStep,
  } = useHandleFormSteps();

  const methods = useForm();

  return (
    <Fragment>
      <div id="faqs">
        <BackButton />
        <FormProvider {...methods}>
          <Container id="formRequestContainer">
            <Row className="justify-content-md-center text-left">
              <Col lg={8}>
                <Card id="formRequest">
                  <div style={{ margin: 30 }}>
                    <div className="justify-content-evenly align-items-center d-flex">
                      <h3 className="text-center titleSelectRequest">
                        {formRequestData.form?.title}
                      </h3>
                    </div>
                    {actualStep === 1 && (
                      <StepOne
                        handleAdvanceStep={handleAdvanceStep}
                        request={actualOption?.identifier}
                        options={formRequestData?.options}
                        handleOptionChange={handleOptionChangeInStepOne}
                      />
                    )}
                    {actualStep === 2 && actualOption?.hasSecondStep ? (
                      <>
                        <StepTwo
                          dateAddDay={dateAddDay}
                          setDate={setDate}
                          date={date}
                          option={actualOption}
                          actualRequest={actualRequestType}
                          handleRequestType={handleRequestType}
                          handleReturnStep={handleReturnStep}
                          handleAdvanceStep={handleAdvanceStep}
                        />
                      </>
                    ) : (
                      actualStep === 3 && (
                        <FinalForm
                          option={actualOption}
                          handleReturnStep={handleReturnStep}
                          user={actualUser}
                        />
                      )
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default FormRequest;
