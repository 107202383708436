import { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import axios from "axios";

const ShouldDeploy = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState();
    const [validateData, setValidateData] = useState(0);

    const [deployClass, setDeployClass] = useState("");

    const [subTitle, setSubtitle] = useState("");

    useEffect(() => {
        axios.get("https://shouldideploy.today/api?tz=America/Argentina/Mendoza").then((res) => {
            setData(res.data);
        });
    }, [validateData]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setValidateData(validateData + 1);
        setDeployClass(data?.shouldideploy ? "deployOk" : "deployError");
        setSubtitle(data?.shouldideploy ? "Go ahead my brave soldier!!!" : "Maybe next monday???");
    };

    return (
        <>
            <div className="category-list-item">
                <button target="_blank" onClick={handleShow} className="cardStyle">
                    <div className="icon">
                        <i className="fa-solid fa-gear fa-beat"></i>
                    </div>
                    <h3>
                        Should I <strong>deploy today?</strong>
                    </h3>
                </button>
            </div>

            <Modal show={show} onHide={handleClose} centered id={deployClass} backdrop="static" keyboard={false}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Modal.Title>Should I deploy today?</Modal.Title>

                    <Row>
                        <Col>
                            <>
                                <p className="messageDeploy">{data?.message}</p>
                                <h3>{subTitle}</h3>
                            </>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ShouldDeploy;
