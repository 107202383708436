import { Container, Image } from "react-bootstrap";

import CloudOne from "../../assets/policy/cloud1.png";
import CloudTwo from "../../assets/policy/cloud2.png";
import Rocket from "../../assets/policy/flyMetoTheMoon.png";
import LogoPossumus from "../../assets/LogoPossumus.svg";
import IconFacebook from "../../assets/policy/facebook.svg";
import IconInstagram from "../../assets/policy/instagram.svg";
import IconLinkedin from "../../assets/policy/LinkedIn.svg";
import IconTwitter from "../../assets/policy/twitter.svg";

const BigFooter = () => {
    return (
        <Container fluid id="bigFooter">
            <Container class="container animateRocket">
                <Image
                    src={CloudOne}
                    alt="Possumus"
                    className="mx-auto d-block cloudOne"
                />
                <Image
                    src={Rocket}
                    alt="Possumus"
                    className="mx-auto d-block rocket"
                />
                <Image
                    src={CloudTwo}
                    alt="Possumus"
                    className="mx-auto d-block cloudTwo"
                />
            </Container>
            <Container class="container">
                <Image
                    src={LogoPossumus}
                    alt="Possumus"
                    className="mx-auto d-block logoFooter "
                />
            </Container>
            <Container className="container text-center" id="subFooter">
                <ul className="nav">
                    <li className="nav-item">
                        <a
                            href="https://www.instagram.com/possumus.tech/"
                            className="nav-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image src={IconInstagram} alt="Instagram" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            href="https://www.facebook.com/possumus.tech/"
                            className="nav-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image src={IconFacebook} alt="Facebook" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            href="https://www.linkedin.com/company/possumustech"
                            class="nav-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Image src={IconLinkedin} alt="Linkedin" />
                        </a>
                    </li>
                    <li className="nav-item">
                        <a
                            href="https://twitter.com/PossumusTech"
                            className="nav-link"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={IconTwitter} alt="Twitter" />
                        </a>
                    </li>
                </ul>
                <p>Copyright © To infinity and beyond. All Rigths Reserved.</p>
            </Container>
        </Container>
    );
};

export default BigFooter;
