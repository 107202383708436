import { Container, Row, Col } from "react-bootstrap";

const Policies = ({data, isLoaded}) => {
    return (
        <Container fluid id="textoPoliticas">
            <Container class="container">
                <h2 class="text-center wow animate__animated  animate__bounceIn">
                    {data?.Titulo}
                </h2>

                {isLoaded
                    ? data?.Items.map((item) => (
                          <Row
                              className=" contentPoliticas wow animate__animated  animate__bounceIn"
                              key={item.id}
                          >
                              <Col>
                                  <h3>{item.Titulo}</h3>
                              </Col>
                              <Col>
                                  <p>{item.Contenido}</p>
                              </Col>
                          </Row>
                      ))
                    : ""}
            </Container>
        </Container>
    );
};

export default Policies;
