import Countdown from "../components/countdown/countdown";
// import MiroEmbed from "../components/miroEmbed/MiroEmbed";
import FooterFaqs from "../components/faqs/footer";
import Header from "../components/faqs/header";
import Items from "../components/faqs/items";
import MenuLateralOpc from "../components/faqs/menuLateralOpc";
import { ThemeProvider } from "../contexts/ThemeContext";

import "../css/faqs.css";

const Faqs = () => {
  return (
    <>
      <ThemeProvider>
        <Countdown />
        {/* <MiroEmbed /> */}
        <div id="faqs" className="content-container">
          <MenuLateralOpc />
          <Header
            title="Faqs"
            description="Numerosos estudios con escaso fundamento científico
            indican que hay 92,7% de posibilidades que lo que
            busques está acá."
            col={6}
            paddingLeft={0}
            offset={3}
          />
          <Items />
          <FooterFaqs />
        </div>
      </ThemeProvider>
    </>
  );
};

export default Faqs;
