import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

export const StepOne = ({
  handleAdvanceStep,
  request,
  options,
  handleOptionChange,
}) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext();

  const onSubmit = handleSubmit(() => handleAdvanceStep());

  return (
    <>
      <Row className="row-width mt-5">
        <Col>
          <Form onSubmit={onSubmit} className="step">
            <Form.Group as={Col} className="inputForm-getIn" controlId="grgrtg">
              <Row>
                <Col
                  lg={12}
                  className="d-grid gap-4"
                  style={{ gridTemplateColumns: "repeat(2,1fr)" }}
                >
                  {options?.map((option) => (
                    <Form.Check
                      className="d-flex align-items-center gap-2"
                      value={option.name}
                      type="radio"
                      aria-label="radio 1"
                      label={option.name}
                      onClick={() => handleOptionChange(option)}
                      checked={request === option.identifier}
                      id={option.identifier}
                      key={option.identifier}
                      {...register("team", { required: true })}
                    />
                  ))}
                </Col>
              </Row>
            </Form.Group>
            {errors?.optionName && (
              <span className="form-error">Debe seleccionar un equipo</span>
            )}
            <Row className="d-grid justify-content-center align-items-center mt-4">
              <Button
                variant="primary"
                type="submit"
                value="submit"
                className="m-0"
                disabled={!isValid}
              >
                Continuar
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
