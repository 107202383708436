import React from "react";
// import axios from "axios";
import { Button, Col, Form, Row } from "react-bootstrap";
import { format } from "date-fns";
import { useFormContext } from "react-hook-form";
export const StepTwo = ({
  values,
  dateAddDay,
  option,
  actualRequest,
  handleRequestType,
  handleReturnStep,
  handleAdvanceStep,
}) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext();

  const onSubmit = handleSubmit(() => handleAdvanceStep());

  // const consultDate = (e) => {
  //   axios
  //     .post(
  //       `https://prod-20.brazilsouth.logic.azure.com:443/workflows/7ab80fbc943b4c3ea658a8dcde46c91e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=rLYkt6dd01KJLfy843NnB4OFEQpi0yVBqBGeEqwNnpU&dateWebinar=${values.fechaPeticion}`
  //     )
  //     .then(
  //       (res) => {
  //         console.log("ver RES", res);
  //       },
  //       (error) => {}
  //     );
  // };
  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group
          as={Col}
          className="inputForm-getIn mt-4"
          onChange={handleRequestType}
          controlId="yourname"
        >
          <Form.Select
            defaultValue={"DEFAULT"}
            required
            {...register("tipoPeticion", {
              required: true,
              validate: (value) => value !== "DEFAULT",
            })}
          >
            <option value="DEFAULT" disabled>
              Seleccione tipo petición *
            </option>
            {option.petitionType?.map((peticion, index) => (
              <option key={index}>{peticion.title}</option>
            ))}
          </Form.Select>
        </Form.Group>
        {errors.tipoPeticion && (
          <span className="form-error">Seleccione un tipo de petición</span>
        )}
        {option.hasPlanification && (
          <>
            <Form.Group
              as={Col}
              className="inputForm-getIn mb-2"
              controlId="yourname"
            >
              <Form.Label>Fecha de planificación *</Form.Label>
              <Form.Control
                type="date"
                name="date"
                placeholder="Fecha"
                min={new Date().toISOString().split("T")[0]}
                {...register("fechaPeticion", { required: true })}
              />
              <Form.Label className="messageWebinarDate">
                {option.petitionType
                  .filter((peticion) => peticion.title === actualRequest)
                  .map(
                    (peticionSelected) =>
                      `Este tipo de petición debe ser solicitada con ${
                        peticionSelected.anticipationDays
                      } días de anticipación. Se recomienda a partir del dia
              ${format(
                dateAddDay(peticionSelected.anticipationDays + 1),
                "dd/MM/yyyy"
              )}.`
                  )}
              </Form.Label>
            </Form.Group>
            {errors.fechaPeticion && (
              <span className="form-error">
                Seleccione una fecha de planificación
              </span>
            )}
            {/* <Form.Group as={Col} className="inputForm-getIn">
              <Button
                onClick={consultDate}
                variant="primary"
                type="submit"
                value="submit"
                className="m-0"
              >
                Consultar fecha
              </Button>
            </Form.Group> */}
          </>
        )}
        {option.hasPriority && (
          <>
            <Form.Group
              as={Col}
              className="inputForm-getIn mt-4"
              controlId="yourname"
            >
              <Form.Select
                defaultValue={"DEFAULT"}
                required
                {...register("levelPriority", {
                  required: true,
                  validate: (value) => value !== "DEFAULT",
                })}
              >
                <option value="DEFAULT" disabled>
                  Seleccione nivel de prioridad *
                </option>
                {option?.priority?.map((prioridad, index) => (
                  <option key={index}>{prioridad.title}</option>
                ))}
              </Form.Select>
            </Form.Group>
            {errors.levelPriority && (
              <span className="form-error">
                Seleccione un nivel de prioridad
              </span>
            )}
          </>
        )}
        <Row className="justify-content-center align-items-center d-flex mt-4">
          <Col className="justify-content-center align-items-center d-flex col-lg-4 col-6">
            <Button
              onClick={handleReturnStep}
              variant="secondary"
              type="submit"
              value="submit"
              className="m-0"
            >
              Volver
            </Button>
          </Col>
          <Col className="justify-content-center align-items-center d-flex col-lg-4 col-6">
            <Button
              variant="primary"
              type="submit"
              value="submit"
              className="m-0"
              disabled={!isValid}
              // disabled={
              //   request === "itaas" &&
              //   (!values.tipoPeticion || !values.levelPriority)
              //     ? true
              //     : !values.tipoPeticion
              // }
            >
              Continuar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
