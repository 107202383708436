import { Container, Form, FloatingLabel, Row, Col } from "react-bootstrap";
import Category from "./category";
import { useContext } from "react";
import { FAQsItemsContext } from "../../contexts/FAQsItemsContext";

const Items = () => {
  const { items, loadedData, HandleInputSearch } = useContext(FAQsItemsContext);
  return (
    <Container id="items">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <FloatingLabel
            controlId="floatingInput"
            label="Buscar"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="name@example.com"
              onChange={HandleInputSearch}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Category
        items={items}
        loadedData={loadedData}
        filter={"Portales"}
        title={"Portales"}
        idName={"portals"}
      />
      <Category
        items={items}
        loadedData={loadedData}
        filter={"Institucional"}
        title={"Institucional"}
        idName={"institutional"}
      />
      <Category
        items={items}
        loadedData={loadedData}
        filter={"SGBP"}
        title={"SGBP"}
        idName={"sgbp"}
      />
      <Category
        items={items}
        loadedData={loadedData}
        filter={"Como_era"}
        title={"¿Cómo era que hacía para...?"}
        idName={"how"}
      />
      <Category
        items={items}
        loadedData={loadedData}
        filter={"Conocimiento"}
        title={"Conocimiento es poder"}
        idName={"power"}
      />
      <Category
        items={items}
        loadedData={loadedData}
        filter={"Diseno"}
        title={"Recursos de diseño"}
        idName={"design"}
      />
      <Category
        items={items}
        loadedData={loadedData}
        filter={"No_Estaba_Arriba"}
        title={"¿No estaba arriba? Debe estar acá..."}
        idName={"here"}
      />
    </Container>
  );
};

export default Items;
