import axios from "axios";
// import { format } from "date-fns";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Dropzone from "react-dropzone-uploader";
import { useFormContext } from "react-hook-form";

export const FinalForm = ({ handleReturnStep, option, user }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useFormContext();

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };
  const changeClass = (id) => {
    const ceve = document.getElementById(id);
    ceve.className = " contentDropzoneOk";
  };

  const removeClass = (id) => {
    const ceve = document.getElementById(id);
    ceve.classList.remove("icontentDropzoneOk");
    ceve.classList.add("contentDropzone");
  };
  const handleChangeStatus = async ({ meta, file }, status) => {
    if (status === "done") {
      setValue("nombreCaptura", file.name);
      const base64 = await convertBase64(file);
      setValue("captura", base64);
    }

    if (status === "headers_received") {
      changeClass("curr");
    } else if (status === "removed") {
      removeClass("curr");
    }
  };
  const sendData = (values) => {
    axios
      .post(
        `https://prod-14.brazilsouth.logic.azure.com:443/workflows/360fc031266a475fb660b3c9d1a5cb85/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ks2Vzhyx4hEwECwXj-tb9qQ2BVBXVbWmtTkcmDk2Tno`,
        values
      )
      .then(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onSubmit = handleSubmit((data) => sendData(data));

  useEffect(() => {
    if (option.destinationEmail) {
      setValue(
        "emailDestiny",
        option.destinationEmail.map((email) => email.title)
      );
    }
    if (user) {
      setValue("nombre", user.displayName || user.name);
      setValue("emailOrigin", user.mail || user.username);
    }
  }, [option, user, setValue]);

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group as={Col} className="inputForm-getIn mt-4">
          <Form.Label>Nombre y Apellido *</Form.Label>
          <Form.Control
            id="nombre"
            name="nombre"
            value={user && user.displayName}
            disabled={user ? true : false}
            placeholder="Nombre y Apellido"
            {...register("nombre", { required: true })}
          ></Form.Control>
        </Form.Group>
        {errors.nombre && (
          <span className="form-error">El nombre es requerido</span>
        )}
        <Form.Group as={Col} className="inputForm-getIn mt-4">
          <Form.Label> Email *</Form.Label>
          <Form.Control
            type="email"
            id="email"
            name="emailOrigin"
            placeholder="Email"
            value={user && user.mail}
            disabled={user ? true : false}
            {...register("emailOrigin", { required: true })}
          ></Form.Control>
        </Form.Group>
        {errors.email && (
          <span className="form-error">El email es requerido</span>
        )}
        {option.destinationEmail?.length > 0 && (
          <Form.Group as={Col} className="inputForm-getIn mt-4">
            <Form.Label> Email de destino *</Form.Label>
            <Form.Control
              type="email"
              id="email"
              name="emailDestiny"
              value={option.destinationEmail.map((email) => email.title)}
              placeholder="Email de destino"
              {...register("emailDestiny")}
              readOnly
            ></Form.Control>
          </Form.Group>
        )}
        <Form.Group as={Col} className="inputForm-getIn mt-4">
          <Form.Label>Asunto *</Form.Label>
          <Form.Control
            id="asunto"
            name="asunto"
            // value={asunto}
            {...register("asunto", { required: true })}
            placeholder="Asunto"
          ></Form.Control>
        </Form.Group>
        {errors.asunto && (
          <span className="form-error">Especifique el asunto</span>
        )}
        <Form.Group
          as={Col}
          className="contentDropzone mt-4"
          controlId="files"
          id="curr"
        >
          <Form.Label>Archivo adjunto</Form.Label>
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            inputContent="Drag and drop files here"
            maxFiles={1}
            id="captura"
            name="captura"
            {...register("captura")}
          />
          <div id="toast"></div>
        </Form.Group>

        <Form.Group as={Col} className="inputForm-getIn mt-4">
          <Form.Label>Detalle de la incidencia</Form.Label>
          <Form.Control
            className="heightTextArea"
            type="textarea"
            id="mensaje"
            name="mensaje"
            as="textarea"
            // value={mensaje}
            placeholder="Detalle de la incidencia"
            {...register("mensaje")}
          ></Form.Control>
        </Form.Group>
        <Row className="justify-content-center align-items-center mt-4">
          <Col className="justify-content-center align-items-center d-flex col-lg-4 col-6">
            <Button
              onClick={handleReturnStep}
              variant="secondary"
              className="m-0"
            >
              Volver
            </Button>
          </Col>
          <Col className="justify-content-center align-items-center d-flex col-lg-4 col-6">
            <Button
              variant="primary"
              type="submit"
              value="submit"
              className="m-0"
              disabled={!isValid}
            >
              Enviar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
