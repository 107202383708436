import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Faqs from "../pages/faqs";
import Licences from "../pages/licences";
import Okrs from "../pages/okrs";
import PlatformCourses from "../pages/platformCourses";
import Policy from "../pages/policy";
import FormRequest from "../pages/formRequest";
import Login from "../pages/login";
import { AuthContext } from "../contexts/AuthContext";

export const AppRouter = () => {
  const { actualUser } = useContext(AuthContext);
  const isAuth = actualUser ? true : false;

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={isAuth ? <Faqs /> : <Navigate to="/login" />}
        />
        <Route
          path="/okrs"
          element={isAuth ? <Okrs /> : <Navigate to="/login" />}
        />
        <Route
          path="/licencias"
          element={isAuth ? <Licences /> : <Navigate to="/login" />}
        />
        <Route
          path="/politica-organizacional"
          element={isAuth ? <Policy /> : <Navigate to="/login" />}
        />
        <Route
          path="/platform-courses"
          element={isAuth ? <PlatformCourses /> : <Navigate to="/login" />}
        />
        <Route
          path="/form-request"
          element={isAuth ? <FormRequest /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
