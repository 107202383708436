import React, { Fragment } from "react";
import Enviroment from "./components/commons/enviroment";
import AppRouter from "./routers/router";
import { ContentFormRequestProvider } from "./contexts/FormRequestContext";
import { msalConfig } from "./config/formRequest/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AuthProvider } from "./contexts/AuthContext";

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <Fragment>
      <Enviroment />
      <MsalProvider instance={msalInstance}>
        <AuthProvider>
          <ContentFormRequestProvider>
            <AppRouter />
          </ContentFormRequestProvider>
        </AuthProvider>
      </MsalProvider>
    </Fragment>
  );
}

export default App;
