import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/layout.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthStrapiProvider } from "./contexts/AuthStrapiV3Context";
import { FAQsItemsProvider } from "./contexts/FAQsItemsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthStrapiProvider>
      <FAQsItemsProvider>
        <App />
      </FAQsItemsProvider>
    </AuthStrapiProvider>
  </React.StrictMode>
);

reportWebVitals();
