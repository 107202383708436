import { addDays, format } from "date-fns";
import { useState } from "react";

export const useHandleFormSteps = () => {
  const [actualOption, setActualOption] = useState({});
  const [actualRequestType, setActualRequestType] = useState("");
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  
  const [actualStep, setActualStep] = useState(1);


  const dateAddDay = (days) => addDays(new Date(date), days)

  function handleOptionChangeInStepOne(option) {
    setActualOption(option);
  }

  function handleRequestType(e) {
    setActualRequestType(e.target.value);
  }
  
  const handleAdvanceStep = () => {
    switch(actualStep){
      case 1:
        if(actualOption?.hasSecondStep){
          setActualStep(actualStep + 1)
        }else{
          setActualStep(actualStep + 2)
        }
        break
      case 2:
        setActualStep(actualStep + 1)
        break
      case 3:
        break
      default:
        setActualStep(1)
        break
    }
    if(actualOption?.hasSecondStep){
      setActualStep(actualStep + 1)
    }else{
      setActualStep(actualStep + 2)
    }
  };

  const handleReturnStep = (e)=>{
    e.preventDefault()
    switch(actualStep){
      case 1:
        break
      case 2:
        setActualStep(actualStep - 1)
        break
      case 3:
        if(actualOption?.hasSecondStep){
          setActualStep(actualStep - 1)
        }else{
          setActualStep(actualStep - 2)
        }
        break
      default:
        setActualStep(1)
        break
    }
  }
  
  return {
    date,
    setDate,
    actualOption,
    handleOptionChangeInStepOne,
    actualRequestType,
    handleRequestType,
    actualStep,
    dateAddDay,
    handleAdvanceStep,
    handleReturnStep
  };
};
