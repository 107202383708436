import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthStrapiContext = createContext({});

export const AuthStrapiProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const url = `${process.env.REACT_APP_URL_API}/auth/local`;

  useEffect(() => {
    async function getToken() {
      try {
        const { data } = await axios.post(url, {
          identifier: "front",
          password: "sz56afZpSVkLqR7",
        });
        if (data) {
          setToken(data.jwt);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    getToken();
  }, [url]);
  return (
    <AuthStrapiContext.Provider value={{ token }}>
      {children}
    </AuthStrapiContext.Provider>
  );
};
