import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const FAQsItemsContext = createContext({});

export const FAQsItemsProvider = ({ children }) => {
  const [items, setitems] = useState({});
  const [loadedData, setLoadedData] = useState(false);
  const [filter, setFilter] = useState(null);

  const { token } = useContext(AuthStrapiContext);
  
  const HandleInputSearch = ({ target }) => {
    setFilter(target.value);
  };

  useEffect(() => {
    const getItems = async () => {
      if (token) {
        setLoadedData(false);
        await axios
          .get(`${process.env.REACT_APP_URL_API}/faqs?_sort=Order:ASC`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            setitems(res.data);
            setLoadedData(true);
          })
          .catch((err) =>
            console.log(
              `Ocurrio un error al obtener los items de las FAQ's: ${err.message}`
            )
          );
      }
    };
    getItems();
  }, [token]);

  useEffect(() => {
    const fitlerItems = async () => {
      if (token && filter) {
        setLoadedData(false);
        await axios
          .get(
            `${process.env.REACT_APP_URL_API}/faqs?Titulo_contains=${filter}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            setitems(res.data);
            setLoadedData(true);
          })
          .catch((err) =>
            console.log(
              `Ocurrio un error al filtrar por lo ingresado en los items: ${err.message}`
            )
          );
      }
    };
    fitlerItems();
  }, [filter, token]);
  return (
    <FAQsItemsContext.Provider value={{ items, loadedData, HandleInputSearch }}>
      {children}
    </FAQsItemsContext.Provider>
  );
};
