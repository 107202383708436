import { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Image } from "react-bootstrap";

import { Pulsar } from "@uiball/loaders";
import { AuthStrapiContext } from "../contexts/AuthStrapiV3Context";

const Okrs = () => {
    const [okrImage, setOkrImage] = useState();
    const [loadedData, setLoadedData] = useState(false);

    const {token} = useContext(AuthStrapiContext)

    useEffect(() => {
        if(token){
            axios.get(`${process.env.REACT_APP_URL_API}/pages-okrs`, {
                headers: { Authorization: `Bearer ${token}` },
              }).then((res) => {
                setOkrImage(res.data.Okrs.url);
                setLoadedData(true);
            }).catch(err => console.log(`Ocurrio un error al obtener la data de la pagina de OKR's: ${err.message}`))
        }
    }, [token]);

    return (
        <>
            {loadedData ? (
                <Image src={okrImage} className="img-fluid" />
            ) : (
                <Container fluid id="fullLoader">
                    <Pulsar size={40} speed={1.75} color="black" />
                </Container>
            )}
        </>
    );
};

export default Okrs;
