import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Banner from "../components/policy/banner";
import Mision from "../components/policy/mision";
import "../css/policy.css";
import Policies from "../components/policy/policies";
import BigFooter from "../components/policy/bigFooter";
import { AuthStrapiContext } from "../contexts/AuthStrapiV3Context";

const Policy = () => {
  const [dataMisionVision, setDataMisionVision] = useState({});
  const [dataPolicies, setDataPolicies] = useState({});
  const [loadedData, setLoadedData] = useState(false);

  const { token } = useContext(AuthStrapiContext);

  useEffect(() => {
    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_URL_API}/pages-politica-organizacional?_locale=es`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setDataPolicies(res.data.Politicas);
          setDataMisionVision(res.data.Mision_Vision);
          setLoadedData(true);
        }).catch(err => console.log(`Ocurrio un error al obtener la data de la pagina de politica organizacional: ${err.message}`))
    }
  }, [token]);

  return (
    <div id="politicas">
      <Banner />
      <Mision
        mision={dataMisionVision.Mision}
        vision={dataMisionVision.Vision}
      />
      <Policies data={dataPolicies} isLoaded={loadedData} />
      <BigFooter />
    </div>
  );
};

export default Policy;
