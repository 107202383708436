import { useAccount, useMsal } from "@azure/msal-react";
import { createContext, useState } from "react";
import { loginRequest } from "../config/formRequest/authConfig";
import { useEffect } from "react";
import { callMsGraph } from "../config/formRequest/graph";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isLoadingUser, setLoadingUser] = useState(true);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [actualUser, setActualUser] = useState(
    JSON.parse(localStorage.getItem("msal.account.keys")) &&
      JSON.parse(
        localStorage.getItem(
          JSON.parse(localStorage.getItem("msal.account.keys"))[0]
        )
      )
  );

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const handleLogin = async () => {
    await instance
      .loginPopup(loginRequest)
      .then((res) => {
        if (res) {
          callMsGraph(res.accessToken).then((result) => {
            setActualUser(result);
            setLoadingUser(false);
            return (window.location.href = "/");
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    instance.initialize().then((res) => {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            callMsGraph(response.accessToken).then((result) => {
              setActualUser(result);
              setLoadingUser(false);
              return;
            });
          }
        });
    });
  }, [account, instance]);
  return (
    <AuthContext.Provider
      value={{ actualUser, isLoadingUser, handleLogin, handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
