import { Container, Row, Col, Image } from "react-bootstrap";
import LogoPossumus from "../../assets/LogoPossumus.svg";
import BackgroundMovile from "../../assets/policy/BAckGroundMovile.png";

const Banner = () => {
    return (
        <Container fluid id="superBanner">
            <Container>
                <Row className=" logoPoliticas">
                    <Image
                        src={LogoPossumus}
                        alt="Possumus"
                        className="d-none d-sm-none d-md-block"
                    />
                </Row>
                <Row>
                    <Col md={6}>
                        <h1 class="animate__animated animate__fadeInDown">
                            Misión, Visión y Política Organizacional.
                        </h1>
                        <Image
                            src={BackgroundMovile}
                            className="d-block d-sm-block d-md-none mobileImg"
                            alt=""
                        />
                    </Col>
                </Row>
                <Row className=" text-center nextChevron">
                    <a href="#misionVision">
                        {" "}
                        <i class="fa-solid fa-chevron-down"></i>
                    </a>
                </Row>
            </Container>
        </Container>
    );
};

export default Banner;
