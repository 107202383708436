import { Image } from "react-bootstrap";
import WLTLogo from "../../assets/LogoWLTFest24.svg";
import "../../css/countdown.css";

function calculateDaysUntil(targetDate) {
  const today = new Date();
  const target = new Date(targetDate);
  
  // Calcula la diferencia en milisegundos
  const differenceInMilliseconds = target.getTime() - today.getTime();
  
  // Convierte la diferencia de milisegundos a días
  const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
  return differenceInDays;
}

const Countdown = () => {

  const daysUntil = calculateDaysUntil('2024-11-09')

  return (
    <section id="countdown">
      <Image src={WLTLogo} className="WLTFloat" alt="We love tech" />
      <p>
        Faltan
        <span className="parpadea"> {daysUntil} </span>
        días para la fiesta de fin de año.
      </p>
      {/* <Button href="https://webservice.possumus.tech/fest2023" target="_blank" className="countdownButton">Más info</Button> */}
      {/* <p>Pronto compartiremos todos los detalles.</p> */}
    </section>
  );
};

export default Countdown;
