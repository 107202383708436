import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const ContentFormRequestContext = createContext({})

export const ContentFormRequestProvider = ({children})=>{

    const [formRequestData, setFormRequestData] = useState({})

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_URL_API}/page-form-request`).then(res => setFormRequestData(res.data))
    },[])

    return <ContentFormRequestContext.Provider value={{formRequestData}}>
        {children}
    </ContentFormRequestContext.Provider>
}