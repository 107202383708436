import { Row } from "react-bootstrap";
import MarkdownView from "react-showdown";
import ShouldDeploy from "./shouldDeply";

const Category = ({ items, loadedData, filter, title, idName }) => {
    return (
        <Row id={idName}>
            <h2 className="text-center">{title}</h2>

            <div className="category-list-wrapper">
                {loadedData ? (
                    items
                        .filter((item) => item.Categoria.includes(filter))
                        .map((itemFiltered) => {
                            return (
                                <>
                                    <div className="category-list-item" key={itemFiltered.id}>
                                        <a href={itemFiltered.Link} className="cardStyle">
                                            <div className="icon">
                                                <i className={itemFiltered.Icono}></i>
                                            </div>

                                            <MarkdownView markdown={itemFiltered.Titulo} />
                                        </a>
                                    </div>
                                    {}
                                </>
                            );
                        })
                ) : (
                    <p>Cargando...</p>
                )}
                {filter === "No_Estaba_Arriba" ? <ShouldDeploy /> : ""}
            </div>
        </Row>
    );
};

export default Category;
