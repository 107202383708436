import { Container, Row, Col } from "react-bootstrap";

import "../css/licences.css";

const Licences = () => {
    return (
        <Container fluid id="licencias">
            <Row>
                <Col md={{ span: 10, offset: 1 }}>
                    <iframe
                        title="Licencias VVHH para FAQ"
                        width="100%"
                        height="1060"
                        src="https://app.powerbi.com/view?r=eyJrIjoiZGU2MDVlOTgtNWVhZC00NGFlLWIyZjMtYmI3NTE1ZjU1ZWU5IiwidCI6ImZmYzVkMWU0LWM4OTYtNGZjNi04ZmM4LTU3NzczNDg5NGZmYyIsImMiOjR9&pageName=ReportSection50fb7ec00a1b2d5fbb90"
                        frameborder="0"
                        allowFullScreen="true"
                    ></iframe>
                </Col>
            </Row>
        </Container>
    );
};

export default Licences;
