import { Container, Row, Col } from "react-bootstrap";

const Mision = ({ mision, vision }) => {
    return (
        <Container fluid id="misionVision">
            <Container fluid id="backgrounDots">
                <Container class="container">
                    <Row>
                        <Col>
                            <div class="card text-center wow animate__animated  animate__backInLeft mision">
                                <div class="card-body">
                                    <h5 class="card-title">Misión</h5>
                                    <p class="card-text">{mision}</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div class="card text-center wow animate__animated  animate__backInRight vision">
                                <div class="card-body">
                                    <h5 class="card-title">Visión</h5>
                                    <p class="card-text">{vision}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Container>
    );
};

export default Mision;
