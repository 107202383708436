import MarkdownView from "react-showdown";
const ItemsMenu = ({ items, loadedData, filter }) => {
    return (
        <>
            {loadedData ? (
                items
                    .filter((item) => item.Categoria.includes(filter))
                    .map((itemFiltered) => (
                        <li key={itemFiltered.id}>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={itemFiltered.Link}
                            >
                                <i className={itemFiltered.Icono}></i>{" "}
                                <MarkdownView markdown={itemFiltered.Titulo} />
                            </a>
                        </li>
                    ))
            ) : (
                <p>Cargando...</p>
            )}
        </>
    );
};

export default ItemsMenu;
