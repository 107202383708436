import { useContext } from "react";
import { Accordion } from "react-bootstrap";
import ItemsMenu from "./itemMenu";
import { FAQsItemsContext } from "../../contexts/FAQsItemsContext";

const MenuLateralOpc = () => {
    const {items, loadedData} = useContext(FAQsItemsContext)
    return (
        <div id="menuContainer">
            <input type="checkbox" id="menu-toggle" />
            <label htmlFor="menu-toggle" className="menu-icon">
                <i className="fa fa-bars"></i>
            </label>

            <div className="slideout-sidebar">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <a href="#portals">
                                <i className="fa-solid fa-globe"></i> Portales
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"Portales"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <a href="#institutional">
                                <i className="fa-solid fa-building"></i>{" "}
                                Institucional
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"Institucional"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <a href="#sgbp">
                                <i className="fa-solid fa-sitemap"></i> SGBP
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"SGBP"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <a href="#how">
                                <i className="fa-solid fa-question"></i> ¿Como
                                era...?
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"Como_era"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                            <a href="#power">
                                <i className="fa-solid fa-graduation-cap"></i>{" "}
                                Conocimiento es poder
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"Conocimiento"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            {" "}
                            <a href="#design">
                                <i className="fa-solid fa-pen-nib"></i> Recursos
                                de diseño
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"Diseno"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>
                            {" "}
                            <a href="#design">
                                <i className="fa-solid fa-bell"></i> Debé estar
                                aca
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <ItemsMenu
                                    items={items}
                                    loadedData={loadedData}
                                    filter={"No_Estaba_Arriba"}
                                />
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};

export default MenuLateralOpc;
