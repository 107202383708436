import { Container, Image } from "react-bootstrap";
import christmasIlustration from '../../assets/faqs/christmasIlustration.png'
import { useThemeContext } from "../../contexts/ThemeContext";

const FooterFaqs = () => {
    const {actualTheme} = useThemeContext()
    return (
        <Container fluid id="footerFaqs" className="text-center">
            {actualTheme === 'christmas' && <Image src={christmasIlustration} alt="christmas ilustrtion" className="christmasIlustration" />}
            <p>
                ¿No estaba?{" "}
                <a href="mailto:vvhh@possumus.tech?subject=faq">Avisanos</a> y
                lo sumamos
            </p>
        </Container>
    );
};

export default FooterFaqs;
